const countries = [
    {
        name: 'United States',
        flag: '🇺🇸',
        currency: '$'
    },
    {
        name: 'Canada',
        flag: '🇨🇦',
        currency: 'CAD'
    },
    {
        name: 'United Kingdom',
        flag: '🇬🇧',
        currency: '£'
    },
    {
        name: 'Australia',
        flag: '🇦🇺',
        currency: 'AUD'
    },
    {
        name: 'Japan',
        flag: '🇯🇵',
        currency: '¥'
    },
    {
        name: 'South Korea',
        flag: '🇰🇷',
        currency: '₩'
    },
    {
        name: 'India',
        flag: '🇮🇳',
        currency: '₹'
    },
    {
        name: 'Mexico',
        flag: '🇲🇽',
        currency: 'MXN'
    },
    {
        name: 'Brazil',
        flag: '🇧🇷',
        currency: 'R$'
    },
    {
        name: 'South Africa',
        flag: '🇿🇦',
        currency: 'ZAR'
    }
];

export default countries;