import React from 'react';
import './Header.css';

const Header = ({handlePrint, invoiceName}) => {
  return (
    <>
        <header className='header-containt'>
            <div>
                <h1 className='invoive-head-txt'>{invoiceName}</h1>
            </div>
            {/* <div>
                <ul className='flex items-center justify-between flex-wrap'>
                    <li className='mr-2 mt-5 bg-gray-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-gray-500 hover:bg-transparent hover:text-gray-500 transition-all duration-300' onClick={handlePrint}>Print</li>
                    <li className='mr-2 mt-5 bg-blue-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-blue-500 hover:bg-transparent hover:text-blue-500 transition-all duration-300'>Download</li>
                    <li className='mt-5 bg-green-500 text-white font-bold py-2 px-8 rounded shadow border-2 border-green-500 hover:bg-transparent hover:text-green-500 transition-all duration-300'>Send</li>
                </ul>
            </div> */}
        </header>
    </>
  )
}

export default Header;