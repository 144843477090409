import React, { useState } from 'react';
import './Table.css';

const Table = ({ list, total, selectedCurrency, price, amount }) => {

  // const [sgstRate, setSgstRate] = useState(9); // replace 9 with your state value
  // const [cgstRate, setCgstRate] = useState(9); // replace 9 with your state value

  // const totalSgstAmount = list.reduce((acc, { price, quantity }) => {
  //   const sgst = (price * quantity * sgstRate) / 100;
  //   return acc + sgst;
  // }, 0);

  // const totalCgstAmount = list.reduce((acc, { price, quantity }) => {
  //   const cgst = (price * quantity * cgstRate) / 100;
  //   return acc + cgst;
  // }, 0);

  return (
    <>
      <table width="100%" className="mb-10">
        <thead>
          <tr className="bg-gray-100 p-6">
            <th className="font-bold">Description</th>
            <th className="font-bold">Quantity</th>
            <th className="font-bold">Price</th>
            <th className="font-bold">SGST</th>
            <th className="font-bold">CGST</th>
            <th className="font-bold">Amount</th>
          </tr>
        </thead>
        {list.map(({ id, description, quantity, price, sgst, cgst, amount }) => (
          <React.Fragment key={id}>
            <tbody>
              <tr className="h-10">
                <td>{description}</td>
                <td>{quantity}</td>
                <td>{selectedCurrency} {price}</td>
                <td>{sgst}</td>
                <td>{cgst}</td>
                <td>{selectedCurrency} {amount}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>

      <div className="table-total-txt">
        <h2 className="table-label-txt">
          Total
        </h2>
        <h2 className="table-sub-txt">
          {selectedCurrency} {total.toLocaleString()}
        </h2>
      </div>

      {/* <div className="table-total-txt">
        <h2 className="table-label-txt">
          SGST ({sgstRate}%)
        </h2>
        <div className="table-input-wrapper">
          <input
            type="number"
            min={0}
            value={sgstRate}
            onChange={(e) => setSgstRate(Number(e.target.value))}
          />
          <span>%</span>
        </div>
        <h2 className="table-sub-txt">
          {selectedCurrency} {totalSgstAmount.toLocaleString()}
        </h2>
      </div>

      <div className="table-total-txt">
        <h2 className="table-label-txt">
          CGST ({cgstRate}%)
        </h2>
        <div className="table-input-wrapper">
          <input
            type="number"
            value={cgstRate}
            onChange={(e) => setCgstRate(Number(e.target.value))}
          />
          <span>%</span>
        </div>
        <h2 className="table-sub-txt">
          {selectedCurrency} {totalCgstAmount.toLocaleString()}
        </h2>
      </div> */}

    </>
  )
}

export default Table;