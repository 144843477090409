import { useRef, useState } from 'react';
import './Packing-slip.css';
import ClientDetails from './components/Packing-Slip/ClientDetails';
import Dates from './components/Packing-Slip/Dates';
import Footer from './components/Packing-Slip/Footer';
import Header from './components/Packing-Slip/Header';
import MainDetail from './components/Packing-Slip/MainDetail';
import Notes from './components/Packing-Slip/Notes';
import Table from './components/Packing-Slip/Table';
import TableForm from './components/Packing-Slip/TableForm';
import ReactToPrint from 'react-to-print';
import InputForm from './components/Packing-Slip/InputForm';

function PackingSlip() {

  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceName, setInvoiceName] = useState("PACKING SLIP")
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [bankName, setBankName] = useState("")
  const [bankAccount, setBankAccount] = useState("")
  const [website, setWebsite] = useState("")
  const [clientName, setClientName] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientPhone, setClientPhone] = useState("")
  const [invoiceNumber, setInvoiceNumber] = useState("")
  const [invoiceDate, setInvoiceDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [notes, setNotes] = useState("")
  const [description, setDescription] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [amount, setAmount] = useState("")
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [width] = useState(641)
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const componentRef = useRef()

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <main className='container'>
        {showInvoice ? (
          <>
            <ReactToPrint trigger={() => <button className='print-btn'><span><i class="fa-solid fa-print icon-edit"></i></span> Print / <span><i class="fa-solid fa-file-lines icon-edit"></i></span> Download</button>}
              content={() => componentRef.current} />
            <div ref={componentRef} className="invoice-output">

              <Header handlePrint={handlePrint} invoiceName={invoiceName} />

              <MainDetail name={name} address={address} email={email} />

              <div className='invoice-combine-part'>
                <ClientDetails clientName={clientName} clientAddress={clientAddress} clientEmail={clientEmail} clientPhone={clientPhone} />

                <Dates invoiceNumber={invoiceNumber} invoiceDate={invoiceDate} dueDate={dueDate} />
              </div>

           <div className='table-containt'>
           <Table description={description} quantity={quantity} price={price} amount={amount} selectedCurrency={selectedCurrency} list={list} setList={setList} total={total} setTotal={setTotal} />
           </div>

              <Notes notes={notes} />

              <Footer />

            </div>

            {/* Edit button  */}
            <button onClick={() => setShowInvoice(false)} className='edit-btn'>Edit Information</button>
          </>
        ) : (
          <>
            <div className='flex flex-col justify-center'>

              <InputForm
                invoiceName={invoiceName}
                setInvoiceName={setInvoiceName}
                name={name}
                setName={setName}
                address={address}
                setAddress={setAddress}
                email={email}
                setEmail={setEmail}
                website={website}
                setWebsite={setWebsite}
                phone={phone}
                setPhone={setPhone}
                clientName={clientName}
                setClientName={setClientName}
                clientAddress={clientAddress}
                setClientAddress={setClientAddress}
                clientEmail={clientEmail}
                setClientEmail={setClientEmail}
                clientPhone={clientPhone}
                setClientPhone={setClientPhone}
                bankName={bankName}
                setBankName={setBankName}
                bankAccount={bankAccount}
                setBankAccount={setBankAccount}
                invoiceNumber={invoiceNumber}
                setInvoiceNumber={setInvoiceNumber}
                invoiceDate={invoiceDate}
                setInvoiceDate={setInvoiceDate}
                dueDate={dueDate}
                setDueDate={setDueDate}
                description={description}
                setDescription={setDescription}
                quantity={quantity}
                setQuantity={setQuantity}
                price={price}
                setPrice={setPrice}
                amount={amount}
                setAmount={setAmount}
                list={list}
                setList={setList}
                total={total}
                setTotal={setTotal}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                notes={notes}
                setNotes={setNotes}
              />

              <button onClick={() => setShowInvoice(true)} className='preview-btn'>Preview Invoice</button>
            </div>
          </>
        )}
      </main>
    </>
  );
}

export default PackingSlip;

