import React from 'react';
import './Notes.css';

const Notes = ({ notes, tc }) => {
    return (
        <>
            <section className="notes-containt">
                <h3 className='notes-label-txt'>Additional Notes</h3>
                <p className="notes-input-txt">{notes}</p>

                <h3 className='notes-label-txt'>Terms & Condition</h3>
                <p className="notes-input-txt">{tc}</p>
            </section>
        </>
    )
}

export default Notes;