import { useRef, useState } from 'react';
import './Packing-slip.css';
import ClientDetails from './components/Estimate-Invoice/ClientDetails';
import Dates from './components/Estimate-Invoice/Dates';
import Footer from './components/Estimate-Invoice/Footer';
import Header from './components/Estimate-Invoice/Header';
import MainDetail from './components/Estimate-Invoice/MainDetail';
import Notes from './components/Estimate-Invoice/Notes';
import Table from './components/Estimate-Invoice/Table';
import TableForm from './components/Estimate-Invoice/TableForm';
import ReactToPrint from 'react-to-print';
import InputForm from './components/Estimate-Invoice/InputForm';

function Estimateinvoice() {

  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceName, setInvoiceName] = useState("Tax Estimate")
  const [name, setName] = useState("")
  const [address, setAddress] = useState("")
  const [Gstin, setGstin] = useState("")
  const [city, setCity] = useState("")
  const [clientName, setClientName] = useState("")
  const [clientAddress, setClientAddress] = useState("")
  const [clientGstin, setClientGstin] = useState("")
  const [estimateNumber, setEstimateNumber] = useState("")
  const [estimateDate, setEstimateDate] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [notes, setNotes] = useState("")
  const [tc, setTc] = useState("")
  const [description, setDescription] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [amount, setAmount] = useState("")
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [width] = useState(641)
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const componentRef = useRef()

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <main className='container'>
        {showInvoice ? (
          <>
            <ReactToPrint trigger={() => <button className='print-btn'><span><i class="fa-solid fa-print icon-edit"></i></span> Print / <span><i class="fa-solid fa-file-lines icon-edit"></i></span> Download</button>}
              content={() => componentRef.current} />
            <div ref={componentRef} className="invoice-output">

              <Header handlePrint={handlePrint} invoiceName={invoiceName} />

              <MainDetail name={name} address={address} Gstin={Gstin} />

              <div className='invoice-combine-part'>
                <ClientDetails clientName={clientName} clientAddress={clientAddress} clientGstin={clientGstin} city={city} />

                <Dates estimateNumber={estimateNumber} estimateDate={estimateDate} dueDate={dueDate} />
              </div>

               <Table description={description} quantity={quantity} price={price} amount={amount} selectedCurrency={selectedCurrency} list={list} setList={setList} total={total} setTotal={setTotal} />

              <Notes notes={notes} tc={tc} />

              <Footer />

            </div>

            {/* Edit button  */}
            <button onClick={() => setShowInvoice(false)} className='edit-btn'>Edit Information</button>
          </>
        ) : (
          <>
            <div className='flex flex-col justify-center'>

              <InputForm
                invoiceName={invoiceName}
                setInvoiceName={setInvoiceName}
                name={name}
                setName={setName}
                address={address}
                setAddress={setAddress}
                Gstin={Gstin}
                setGstin={setGstin}
                city={city}
                setCity={setCity}
                clientName={clientName}
                setClientName={setClientName}
                clientGstin={clientGstin}
                setClientGstin={setClientGstin}
                clientAddress={clientAddress}
                setClientAddress={setClientAddress}
                estimateNumber={estimateNumber}
                setEstimateNumber={setEstimateNumber}
                estimateDate={estimateDate}
                setEstimateDate={setEstimateDate}
                dueDate={dueDate}
                setDueDate={setDueDate}
                description={description}
                setDescription={setDescription}
                quantity={quantity}
                setQuantity={setQuantity}
                price={price}
                setPrice={setPrice}
                amount={amount}
                setAmount={setAmount}
                list={list}
                setList={setList}
                total={total}
                setTotal={setTotal}
                // sgst={sgst}
                // setSGST={setSGST}
                // cgst={cgst}
                // setCGST={setCGST}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                notes={notes}
                setNotes={setNotes}
                tc={tc}
                setTc={setTc}
              />

              <button onClick={() => setShowInvoice(true)} className='preview-btn'>Preview Invoice</button>
            </div>
          </>
        )}
      </main>
    </>
  );
}

export default Estimateinvoice;

