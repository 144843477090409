import React from 'react';
import TableForm from './TableForm';
import './InputForm.css';

const InputForm = ({ invoiceName, setInvoiceName, name, setName, address, setAddress, email, setEmail, website, setWebsite, phone, setPhone, clientName, setClientName, clientAddress, setClientAddress, clientEmail, setClientEmail, clientPhone, setClientPhone, invoiceNumber, setInvoiceNumber, invoiceDate, setInvoiceDate, dueDate, setDueDate, description, setDescription, amount, setAmount, quantity, setQuantity, price, setPrice, list, setList, total, setTotal, selectedCurrency, setSelectedCurrency, notes, setNotes }) => {


    return (
        <div id='inputform'>
            <div className=''>
                <div className='sub-containt1'>

                    <div className='head-containt1'>
                        <h2 className='form-info-text'>Your Company name</h2>
                        {/* <label htmlFor='name'>Enter Your Name</label> */}
                        <input
                            // className='w-2/5 bg-transparent border-transparent'
                            type="text"
                            name='text'
                            id='name'
                            placeholder='Your Name'
                            autoComplete='off'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        {/* <label htmlFor='address'>Enter Your Address</label> */}
                        <input
                            type="text"
                            name='address'
                            id='address'
                            placeholder="Company's address"
                            autoComplete='off'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />

                        {/* <label htmlFor='email'>Enter Your Email</label> */}
                        <input
                            type="text"
                            name='email'
                            id='email'
                            placeholder='Your Email'
                            autoComplete='off'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        {/* <label htmlFor='website'>Enter Your Website</label> */}
                        <input
                            type="url"
                            name='website'
                            id='website'
                            placeholder='Your Website'
                            autoComplete='off'
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                        />

                        {/* <label htmlFor='phone'>Enter Your Phone Number</label> */}
                        <input
                            type="tel"
                            name='phone'
                            id='phone'
                            placeholder='Your Phone number'
                            autoComplete='off'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>

                    <div className='head-containt2'>
                        <input 
                            type="text"
                            name="invoiceName"
                            id="invoiceName"
                            placeholder="Packing slip"
                            autoComplete="off"
                            className='title-input-bar'
                            value={invoiceName}
                            onChange={(e) => setInvoiceName(e.target.value)} 
                        />
                    </div>
                </div>
                <div className='sub-containt2'>

                    <div className=''>
                        <h2 className='form-info-text'>Bill To</h2>
                        {/* <label htmlFor="clientName">Enter your client's name</label> */}
                        <input
                            type="text"
                            name="clientName"
                            id="clientName"
                            placeholder="Customer Name"
                            autoComplete="off"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                        />

                        {/* <label htmlFor="clientAddress">Enter your client's address</label> */}
                        <input
                            type="text"
                            name="clientAddress"
                            id="clientAddress"
                            placeholder="Customer Address"
                            autoComplete="off"
                            value={clientAddress}
                            onChange={(e) => setClientAddress(e.target.value)}
                        />

                        {/* <label htmlFor='clientEmail'>Enter Your client's Email</label> */}
                        <input
                            type="text"
                            name='clientEmail'
                            id='clientEmail'
                            placeholder='Customer Email'
                            autoComplete='off'
                            value={clientEmail}
                            onChange={(e) => setClientEmail(e.target.value)}
                        />

                        {/* <label htmlFor='clientPhone'>Enter Your client's Phone Number</label> */}
                        <input
                            type="tel"
                            name='clientPhone'
                            id='clientPhone'
                            placeholder='Customer Phone number'
                            autoComplete='off'
                            value={clientPhone}
                            onChange={(e) => setClientPhone(e.target.value)}
                        />
                    </div>

                    <div className='form-invoice-containt'>
                        <div className='form-invoice'>
                            <label htmlFor="invoiceNumber">Invoice Number : </label>
                            <input
                                type="text"
                                name="invoiceNumber"
                                id="invoiceNumber"
                                placeholder="Invoice Number"
                                autoComplete="off"
                                className='input-margin'
                                value={invoiceNumber}
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                            />
                        </div>

                        <div className='form-invoice'>
                            <label htmlFor="invoiceDate">Invoice Date : </label>
                            <input
                                type="date"
                                name="invoiceDate"
                                id="invoiceDate"
                                placeholder="Invoice Date"
                                autoComplete="off"
                                value={invoiceDate}
                                onChange={(e) => setInvoiceDate(e.target.value)}
                            />
                        </div>

                        <div className='form-invoice'>
                            <label htmlFor="dueDate">Due Date : </label>
                            <input
                                type="date"
                                name="dueDate"
                                id="dueDate"
                                placeholder="Invoice Date"
                                autoComplete="off"
                                value={dueDate}
                                onChange={(e) => setDueDate(e.target.value)}
                            />
                        </div>
                    </div>

                </div>
            </div>

            {/* user define color section  */}
            {/* <label htmlFor='usercolor' >Choose color</label>
            <input
                type="text"
                name='userColor'
                id='userColor'
                placeholder='Enter Your userColor'
                autoComplete='off'
                value={userColor}
                onChange={(e) => setUserColor(e.target.value)}
            /> */}

            {/* <label htmlFor='bankName'>Enter Your BankName</label>
            <input
                type="text"
                name='bankName'
                id='bankName'
                placeholder='Enter Your bankName'
                autoComplete='off'
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
            />

            <label htmlFor='bankAccount'>Enter Your Bank Account Number</label>
            <input
                type="text"
                name='bankAccount'
                id='bankAccount'
                placeholder='Enter Your Bank Account Number'
                autoComplete='off'
                value={bankAccount}
                onChange={(e) => setBankAccount(e.target.value)}
            /> */}

            <article>
                <TableForm
                    description={description}
                    setDescription={setDescription}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    price={price}
                    setPrice={setPrice}
                    amount={amount}
                    setAmount={setAmount}
                    list={list}
                    setList={setList}
                    total={total}
                    setTotal={setTotal}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                />
            </article>

            <label htmlFor="notes">Notes</label><br />
            <textarea
                name="notes"
                id="notes"
                rows="4"
                placeholder="Additional notes to the client"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
            >It was great doing business with you.</textarea>
        </div>
    )
}

export default InputForm;