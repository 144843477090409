import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';
import logoimg from "../assets/invoice-logo1.png";

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <button onClick={toggleSidebar} className="sidebar-btn">
                {isOpen ? <i class="fa-solid fa-xmark"></i> : <i class="fa-solid fa-bars"></i> }
            </button>

            <ul className='sidebar-ul-txt'>
                <li className='sidebar-li-txt logo'>
                    <img src={logoimg} alt="not found" width="190" />
                </li>
                <li className='sidebar-li-txt'>
                    <NavLink to='/' className="sidebar-anchor-txt">Packing Slip</NavLink>
                </li>
                <li className='sidebar-li-txt'>
                    <NavLink to='/Estimate-Invoice' className="sidebar-anchor-txt">Estimate Invoice</NavLink>
                </li>
                <li className='sidebar-li-txt'>
                    <NavLink to='/Estimate-Invoice' className="sidebar-anchor-txt">Tax Invoice</NavLink>
                </li>
                <li className='sidebar-li-txt'>
                    <NavLink to='/Barcode-genrator' className="sidebar-anchor-txt">Barcode Generator</NavLink>
                </li>
            </ul>
        </div>
    )
}

export default Navbar;