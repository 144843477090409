import React from 'react';
import './Dates.css';

const Dates = ({ invoiceNumber, invoiceDate, dueDate }) => {
    return (
        <>

            <article className="dates-containt">
                <ul className='dates-ul-txt'>
                    <li className="dates-li-txt">
                        <h4 className="dates-span-txt">Estimate number :</h4>
                        <p className='dates-fill-txt'>{invoiceNumber}</p> 
                    </li>
                    <li className="dates-li-txt ">
                        <h4 className="dates-span-txt">Estimate date :</h4> 
                        <p className='dates-fill-txt'>{invoiceDate}</p> 
                    </li>
                    <li className="dates-li-txt ">
                        <h4 className="dates-span-txt">Due date :</h4> 
                        <p className='dates-fill-txt'>{dueDate}</p> 
                    </li>
                </ul>
            </article>
        </>
    )
}

export default Dates;
