import React from 'react';
import './Table.css';

const Table = ({ list, total, selectedCurrency }) => {
  return (
    <>
      <table width="100%" className="mb-10">
        <thead>
          <tr className="bg-gray-100 p-6">
            <th className="font-bold">Description</th>
            <th className="font-bold">Quantity</th>
            <th className="font-bold">Price</th>
            <th className="font-bold">Amount</th>
          </tr>
        </thead>
        {list.map(({ id, description, quantity, price, amount }) => (
          <React.Fragment key={id}>
            <tbody>
              <tr className="h-10">
                <td>{description}</td>
                <td>{quantity}</td>
                <td>{selectedCurrency} {price}</td>
                <td>{selectedCurrency} {amount}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>

      <div className="table-total-txt">
        <h2 className="table-label-txt">
          Total 
        </h2>
        <h2 className="table-sub-txt">
        {selectedCurrency} {total.toLocaleString()}
        </h2>
      </div>
    </>
  )
}

export default Table;