import React from 'react';
import './Footer.css';
import footerimg from "../../assets/invoice-logo1.png";

const Footer = () => {
  return (
    <>
      <footer className="footer-containt">
         <a className='footer-link-txt'>Powered by</a>
         <img src={ footerimg } alt="not found" width="170" height="40"/>
      </footer>
    </>
  )
}

export default Footer;