import React from 'react';
import './ClientDetail.css';

const ClientDetails = ({ clientName, clientAddress }) => {
  return (
    <>
      <section className="clientdetail-containt">
        <h3 className='clientdetail-side-txt'>Bill To.</h3>
        <h2 className="clientdetail-sub-txt">{clientName}</h2>
        <p className="clientdetail-sub-txt">{clientAddress}</p>
      </section>
    </>
  )
}

export default ClientDetails;