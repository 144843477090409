import React, { useRef, useState } from 'react';
import "./bg.css";
// import "../../Packing-slip.css";
import QRCode from 'qrcode.react';

const Barcodegenerator = () => {

    const [inputText, setInputText] = useState('');
    const [qrCodeText, setQRCodeText] = useState('');

    // generate QR code
    const generateQRCode = () => {
        setQRCodeText(inputText);
    }

    // download QR code
    const downloadQRCode = () => {
        const qrCodeURL = document.getElementById('qrCodeEl')
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        console.log(qrCodeURL)
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QR_Code.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    return (
        <>
            <main className='qr-container'>

                <div className='qr-heading-part'>
                    <h2 className='qr-head-txt'>Barcode Generator</h2>
                    <p className='qr-sub-txt'>A barcode generator is a free and easy-to-use way to create barcodes for the items in your inventory. Follow 5 simple steps and get started right now.</p>
                </div>

                <div className='qr-containt'>
                    <div className="qr-input-part">
                        <label className='qr-label-txt'>Create Barcode</label>
                        <input
                            type="text"
                            placeholder="Enter Your Text or Link"
                            className='qr-input-txt'
                            value={inputText}
                            onChange={e => setInputText(e.target.value)}
                        />
                        <input
                            type="button"
                            value="Generate Barcode"
                            className='qr-btn extra-margin'
                            onClick={generateQRCode}
                        />
                    </div>
                    <div className="qr-output-part">
                        <QRCode
                            id="qrCodeEl"
                            // size={250}
                            className="edit-qr"
                            value={qrCodeText}
                        />
                        <br />
                        <input
                            type="button"
                            className="qr-btn"
                            value="Download"
                            onClick={downloadQRCode}
                        />
                    </div>
                </div>
            </main>
        </>
    )
}

export default Barcodegenerator;