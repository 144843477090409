import React from 'react';
import './MainDetail.css';

const MainDetail = ({ name, address }) => {
  return (
    <>
      <section className="maindetail-containt">
        <h3  className='maindetail-side-txt'>Bill From.</h3>
        <h2 className="maindetail-sub-txt">{name}</h2>
        <p className="maindetail-sub-txt">{address}</p>
      </section>
    </>
  )
}

export default MainDetail;