import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import countries from '../../Coutries';
import './TableForm.css';

const TableForm = ({ description, setDescription, quantity, setQuantity, price, setPrice, amount, setAmount, list, setList, total, setTotal, selectedCurrency, setSelectedCurrency }) => {

    const [isEditing, setIsEditing] = useState(false);

    // submit form button 
    const handleSubmit = (e) => {
        e.preventDefault()

        if (!description && !quantity && !price) {
            alert("Please fill in all inputs")
        }
        else {
            const newItems = {
                // it is use for every time generate unique id 
                id: uuidv4(),
                description,
                quantity,
                price,
                amount,
            }
            setDescription("")
            setQuantity("")
            setPrice("")
            setAmount("")
            setList([...list, newItems])
            setIsEditing(false)
            console.log(list)
        }

    }

    // calculation of amount function 
    useEffect(() => {
        const claculateAmount = (amount) => {
            setAmount(quantity * price)
        }

        claculateAmount(amount)
    }, [amount, price, quantity, setAmount]);

    // calculate total amounts of items in table 
    useEffect(() => {
        let row = document.querySelectorAll(".amount")
        let sum = 0

        for (let i = 0; i < row.length; i++) {
            if (row[i].className === "amount") {
                sum += isNaN(row[i].innerHTML) ? 0 : parseInt(row[i].innerHTML)
                setTotal(sum)
            }
        }
    })

    // Edit function 
    const editRow = (id) => {
        const editingRow = list.find((row) => row.id === id)
        setList(list.filter((row) => row.id !== id))
        setIsEditing(true)
        setDescription(editingRow.description)
        setQuantity(editingRow.quantity)
        setPrice(editingRow.price)
        setAmount(editingRow.amount)
    }

    // delete function
    const deleteRow = (id) => setList(list.filter((row) => row.id !== id))

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='tableform-containt'>
                    <div className="sub-tableform ">
                        <p className='label-txt'><label className='th-text' htmlFor="description">Item description</label></p>
                        <input
                            type="text"
                            name="description"
                            id="description"
                            placeholder="Item description"
                            className='table-form-input'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>

                    <div className="sub-tableform">
                        <p className='label-txt'><label className='th-text' htmlFor="quantity">Quantity</label></p>
                        <input
                            type="text"
                            name="quantity"
                            id="quantity"
                            placeholder="Quantity"
                            className='table-form-input'
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                    </div>

                    <div className="sub-tableform">
                        <p className='label-txt'><label className='th-text' htmlFor="price">Price</label></p>
                        <input
                            type="text"
                            name="price"
                            id="price"
                            placeholder="Price"
                            className='table-form-input'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>

                    <div className="sub-tableform">
                        <p className='label-txt'><label className='th-text' htmlFor="amount">Amount</label></p>
                        <p className='table-form-input'>{selectedCurrency} {amount}</p>
                    </div>

                </div>

                <button type='submit' className=''>
                    {/* for show text edit & add  */}
                    {isEditing ? "Editing Row Items" : "Add Table Item"}
                </button>

                {/* <div className="">
                    <label for="countries">Choose a Currency:</label>
                    <select name="countries" id="countries" value={selectedCurrency}
                        onChange={e => setSelectedCurrency(e.target.value)}>
                        {Object.keys(countries).map((code) => (
                            <option key={code} value={countries[code].currency}>{countries[code].name}..... {countries[code].currency}</option>
                        ))}
                    </select>
                </div> */}

            </form>

            {/* table items  */}
            <div className='main'>
            <table width="100%" className="">
                <thead>
                    <tr className="">
                        <th className="">Description</th>
                        <th className="">Quantity</th>
                        <th className="">Price</th>
                        <th className="">Amount</th>
                        <th className="">Edit</th>
                        <th className="">Delete</th>
                    </tr>
                </thead>
                {list.map(({ id, description, quantity, price, amount }) => (
                    <React.Fragment key={id}>
                        <tbody>
                            <tr className="">
                                <td>{description}</td>
                                <td>{quantity}</td>
                                <td>{price}</td>
                                <td className="amount"> {amount}</td>
                                <td>
                                    <button onClick={() => editRow(id)} className="table-button">
                                        <AiOutlineEdit className="edit-icon" />
                                    </button>
                                </td>
                                <td>
                                    <button onClick={() => deleteRow(id)} className="table-button">
                                        <AiOutlineDelete className="delete-icon" />
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </React.Fragment>
                ))}
            </table>
            </div>

            <div>
                <h2 className="total-amount">
                    Total {selectedCurrency} {total}
                </h2>
            </div>
        </>
    )
}

export default TableForm;