import React from 'react';
import './App.css';
import PackingSlip from './Packing-slip';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Estimateinvoice from './Estimate-invoice';
import Barcodegenerator from './components/Barcode-Generator/Barcode-generator';

function App() {

  return (
    <>
      <BrowserRouter>
        <div className='app-containt'>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<PackingSlip />} />
            <Route exact path='/Estimate-invoice' element={<Estimateinvoice />} />
            <Route exact path='/Barcode-genrator' element={<Barcodegenerator />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;

