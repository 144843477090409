import React from 'react';
import TableForm from './TableForm';
import './InputForm.css';

const InputForm = ({ invoiceName, setInvoiceName, name, setName, address, setAddress, email, setEmail, Gstin, setGstin, clientName, setClientName, clientAddress, setClientAddress, clientGstin, setClientGstin, city, setCity, estimateNumber, setEstimateNumber, estimateDate, setEstimateDate, dueDate, setDueDate, description, setDescription, amount, setAmount, quantity, setQuantity, price, setPrice, list, setList, total, setTotal, selectedCurrency, setSelectedCurrency, notes, setNotes, tc, setTc }) => {


    return (
        <div id='inputform'>
            <div className=''>
                <div className='sub-containt1'>

                    <div className='head-containt1'>
                        <h2 className='form-info-text'>Your Company name</h2>
                        {/* <label htmlFor='name'>Enter Your Name</label> */}
                        <input
                            type="text"
                            name='text'
                            id='name'
                            placeholder='Your Name'
                            autoComplete='off'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />

                        {/* <label htmlFor='companygst'>gst number</label> */}
                        <input
                            type="number"
                            name='companygst'
                            id='companygst'
                            placeholder="Company's GSTIN"
                            autoComplete='off'
                            value={Gstin}
                            onChange={(e) => setGstin(e.target.value)}
                        />

                        {/* <label htmlFor='address'>Enter Your Address</label> */}
                        <input
                            type="text"
                            name='address'
                            id='address'
                            placeholder="Company's address"
                            autoComplete='off'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />

                        {/* <label htmlFor='city'>Enter Your city</label> */}
                        <input
                            type="text"
                            name='city'
                            id='city'
                            placeholder='City'
                            autoComplete='off'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                    </div>

                    <div className='head-containt2'>
                        <input
                            type="text"
                            name="invoiceName"
                            id="invoiceName"
                            placeholder="Packing slip"
                            autoComplete="off"
                            className='title-input-bar'
                            value={invoiceName}
                            onChange={(e) => setInvoiceName(e.target.value)}
                        />
                    </div>
                </div>
                <div className='sub-containt2'>

                    <div className=''>
                        <h2 className='form-info-text'>Bill To</h2>
                        {/* <label htmlFor="clientName">Enter your client's name</label> */}
                        <input
                            type="text"
                            name="clientName"
                            id="clientName"
                            placeholder="Client's Name"
                            autoComplete="off"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                        />

                        {/* <label htmlFor='clientgst'>client's gst number</label> */}
                        <input
                            type="number"
                            name='clientgst'
                            id='clientgst'
                            placeholder="Client's GSTIN"
                            autoComplete='off'
                            value={clientGstin}
                            onChange={(e) => setClientGstin(e.target.value)}
                        />

                        {/* <label htmlFor="clientAddress">Enter your client's address</label> */}
                        <input
                            type="text"
                            name="clientAddress"
                            id="clientAddress"
                            placeholder="Client's Address"
                            autoComplete="off"
                            value={clientAddress}
                            onChange={(e) => setClientAddress(e.target.value)}
                        />

                        {/* <label htmlFor='clientcity'>city</label> */}
                        <input
                            type="text"
                            name='clientcity'
                            id='clientcity'
                            placeholder='City'
                            autoComplete='off'
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />

                    </div>

                    <div className='form-invoice-containt'>
                        <div className='form-invoice'>
                            <label htmlFor="EstimateNumber">Estimate Number : </label>
                            <input
                                type="text"
                                name="EstimateNumber"
                                id="EstimateNumber"
                                placeholder="Estimate Number"
                                autoComplete="off"
                                className='input-margin'
                                value={estimateNumber}
                                onChange={(e) => setEstimateNumber(e.target.value)}
                            />
                        </div>

                        <div className='form-invoice'>
                            <label htmlFor="EstimateDate">Estimate Date : </label>
                            <input
                                type="date"
                                name="EstimateDate"
                                id="EstimateDate"
                                placeholder="Estimate Date"
                                autoComplete="off"
                                value={estimateDate}
                                onChange={(e) => setEstimateDate(e.target.value)}
                            />
                        </div>

                        <div className='form-invoice'>
                            <label htmlFor="dueDate">Due Date : </label>
                            <input
                                type="date"
                                name="dueDate"
                                id="dueDate"
                                placeholder="Estimate Date"
                                autoComplete="off"
                                value={dueDate}
                                onChange={(e) => setDueDate(e.target.value)}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <article>
                <TableForm
                    description={description}
                    setDescription={setDescription}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    price={price}
                    setPrice={setPrice}
                    amount={amount}
                    setAmount={setAmount}
                    list={list}
                    setList={setList}
                    total={total}
                    setTotal={setTotal}
                    selectedCurrency={selectedCurrency}
                    setSelectedCurrency={setSelectedCurrency}
                />
            </article>

            <label htmlFor="notes">Notes</label><br />
            <textarea
                name="notes"
                id="notes"
                rows="4"
                placeholder="Additional notes to the client"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
            >It was great doing business with you.</textarea>

            <label htmlFor="tcnotes">Terms & Condition</label><br />
            <textarea
                name="tcnotes"
                id="tcnotes"
                rows="4"
                placeholder="This estimate is valid only  if accepted on or before the expiry date."
                value={tc}
                onChange={(e) => setTc(e.target.value)}
            >It was great doing business with you.</textarea>
        </div>
    )
}

export default InputForm;